import React from "react"
import Layout from "../components/layout.js"
import * as styles from "./register.module.css"
import Meta from "../components/meta.js"
export default function Register() {
  return (
    // <div>
    //   <head>
    //     <title>HTML Meta Tag</title>
    //     {/*<meta
    //       http-equiv="refresh"
    //       content="2; url = https://docs.google.com/forms/d/e/1FAIpQLSc6c4zwDZTskonae4hhhmwH7C9FxLRvRGpEKlre5g4ZE4LYng/viewform"
    //     />*/}
    //   </head>
    //   <Layout />

    //   <div
    //     style={{
    //       width: `100%`,
    //     }}
    //   >
    //     {/* <p>Redirecting to form</p> */}
    //     <iframe className={styles.frame}
    //       style={{
    //         display: `block`,
    //         margin: `3rem auto`,
    //         height: `550px`,

    //       }}
    //       src="https://docs.google.com/forms/d/e/1FAIpQLSc6c4zwDZTskonae4hhhmwH7C9FxLRvRGpEKlre5g4ZE4LYng/viewform"
    //       width="100%"
    //       frameborder="0"
    //       marginwidth="0"
    //     >
    //       Loading…
    //     </iframe>
    //   </div>
    // </div>
    <Layout>
      <Meta />
      <meta http-equiv="refresh" content="0;url=https://forms.gle/GZZEiPBUE7MqcHa36" />
    </Layout>
  )
}
